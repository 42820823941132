import React from "react";
import { graphql } from "gatsby";
import Header from '../components/Header';
import '../styles/main.css';
import '../styles/documentation.css';
import DocumentationMenu from "../components/DocumentationMenu";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <div>
      <Header />

      <div className="viewport">
        <main style={{ width: '100%', maxWidth: '1280px', margin: '0 auto' }}>
          <div className="documentation-container" style={{ marginTop: '6rem' }}>
            <div className="documentation-post">
              <h1>{frontmatter.title}</h1>
              <div
                className="documentation-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />

              <hr />

              <div>
                <span>Last edited on: {frontmatter.date}</span>
              </div>
            </div>
          </div>
        </main>

        <aside>
          <DocumentationMenu query={data}/>
        </aside>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
    allSitePage {
      edges {
        node {
          path
          pageContext
        }
      }
    }
    site {
      siteMetadata {
        APIs {
          definition {
            name
            path
          }
        }
      }
    }
  }
`