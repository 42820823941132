const parseMenuItems = (queryResult) => {
    let result = {};

    queryResult.allSitePage.edges.forEach((value) => {
        if (Object.keys(value.node.pageContext).length === 0) {
            return;
        }

        const group = value.node.pageContext.group;

        if (!result.hasOwnProperty(group)) {
            result[group] = [];
        }

        result[group].push({ title: value.node.pageContext.title, slug: value.node.pageContext.slug, path: value.node.path });
    });

    return result;
};

export default parseMenuItems;