import * as React from 'react';

const headingStyles = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '128px',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
    zIndex: 100,
    background: '#20232a',
    color: '#bababd'
}  

const Header = (props) => {
    return (
        <header style={headingStyles}>
            <a href="/" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', textDecoration: 'none', color: 'inherit'}}>
                <img alt="" src="https://web.calidos.be/wp-content/uploads/2020/09/Logo-TnT-vLatest.png" width="250px" />
                <h2 style={{ color: '#bababd' }}>API Documentation</h2>
            </a>

            <div style={{marginRight: '1rem'}}>
                <span style={{ color: '#bababd' }}>v1.0.0</span>
            </div>
        </header>
    )
};

export default Header;