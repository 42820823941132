import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import parseMenuItems from '../helpers/parse-menu-items';

const DocumentationMenu = (props) => {
    const menuItems = parseMenuItems(props.query);
    const APIs = props.query.site.siteMetadata.APIs.definition;
    
    return (
        <div className="documentation-menu">
            {Object.keys(menuItems).map((menuGroupKey) => (
                <div key={menuGroupKey} className="documentation-menu__group">
                    <h3>{menuGroupKey}</h3>

                    <ul>
                        {menuItems[menuGroupKey].map((item) => (
                            <li key={item.slug}><a href={item.path}>{item.title}</a></li>
                        ))}
                    </ul>
                </div>
            ))}

            <div className="documentation-menu__group">
                <h3>APIs</h3>

                <ul>
                    {APIs.map((api) => (
                        <li key={api.name}><a href={api.path}>{api.name}</a></li>
                    ))}
                </ul>
            </div>
        </div>
    )
};

export default DocumentationMenu;
